/**
 * Menus
 */
 .aki-tab-title {
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: letter;
  line-height: 1;
  letter-spacing: normal;
  color: #000000;
}

.tile {
  box-shadow: -4px 5px 8px 0 rgba(67, 86, 100, 0.12), -1px 2px 4px 0 rgba(67, 86, 100, 0.1);
  background-color: #ffffff;
}

.sub-title {
  width: 100%;
  height: 7%;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: #192038;
}

.contenu-shadow {
  height: 92%;
  border-top: 1px solid #ebecf4;
  background-color: rgba(143, 155, 179, 0.05);
}

.search-control {
  border: 1px solid #bdbdbd;
  border-radius: 1.5rem;
}

.search-control:focus {
  outline: none !important;
  border:1px solid #DC2626;
}

#search {
  background-image: url('/assets/img/search.svg');
  background-repeat: no-repeat;
  position: absolute;
  right: 0.5rem;
  display: inline-block;
  height: 100%;
  width: 20px;
  top: 25%;
}

.rounded-focus-hack {
  border-radius: 2rem;
}
.rounded-focus-hack:focus {
  outline: none !important;
  border:1px solid transparent;
}



